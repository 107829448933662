
import { defineComponent } from "vue";
import CenterCardLayout from "@/components/layouts/CenterCardLayout.vue";
import SignupForm from "@/components/forms/SignupForm.vue";

export default defineComponent({
  components: {
    CenterCardLayout,
    SignupForm
  }
});


import { defineComponent } from "vue";
import LoginForm from "@/components/forms/LoginForm.vue";
import CenterCardLayout from "@/components/layouts/CenterCardLayout.vue";

export default defineComponent({
  components: {
    LoginForm,
    CenterCardLayout
  }
});

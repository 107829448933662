<template>
  <center-card-layout>
    <template #leftImage>
      <img src="@/assets/images/athletes.svg" class="responsive"/>
    </template>
    <signup-form/>
  </center-card-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CenterCardLayout from "@/components/layouts/CenterCardLayout.vue";
import SignupForm from "@/components/forms/SignupForm.vue";

export default defineComponent({
  components: {
    CenterCardLayout,
    SignupForm
  }
});
</script>


<style scoped lang="scss">
.responsive {
  width: 100%;
  height: auto;
}
</style>
<template>
  <center-card-layout>
    <template #leftImage>
      <img src="@/assets/images/athletes.svg" class="responsive"/>
    </template>
    <login-form />
    <p class="text-end mb mt-n2 menu-login">
     <router-link :to="{ name: 'signup' }" class="signup">
        Sign up
      </router-link>
      <router-link :to="{ name: 'forgot-password' }" class="text-muted">
        Forgot password ?
      </router-link>
    </p>
  </center-card-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoginForm from "@/components/forms/LoginForm.vue";
import CenterCardLayout from "@/components/layouts/CenterCardLayout.vue";

export default defineComponent({
  components: {
    LoginForm,
    CenterCardLayout
  }
});
</script>

<style scoped lang="scss">
.responsive {
  width: 100%;
  height: auto;
}
.menu-login{
    display: flex;
    justify-content: space-between;
}
.signup{
    color:#267FFF ;
}
</style>